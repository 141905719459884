import { useState, createContext, useContext, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

// Services
import { getUserConnected, getCountMessageNotRead } from './index.service';

// Utils
import client from './api';

export const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
    const [lang, setLang] = useState("fr");
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [loggedIn, setLoggedIn] = useState(!!localStorage.getItem("token"));
    
    const [user, setUser] = useState(null);
    const [workspace, setWorkspace] = useState({});
    const [listWorkspace, setListWorkspace] = useState([]);
    const [nbMessageNotRead, setNbMessageNotRead] = useState();
    const [isTrialOffer, setIsTrialOffer] = useState(false);
    const [subscription, setSubscription] = useState({});

    const {
        data: userConnectedData,
    } = useQuery({
        queryKey: ['user-connected', loggedIn],
        queryFn: getUserConnected,
        enabled: loggedIn,
    });

    const {
        data: msgNotReadCount,
    } = useQuery({
        queryKey: ['msg-not-read-count', loggedIn],
        queryFn: getCountMessageNotRead,
        enabled: loggedIn,
    });

    useEffect(() => {
        if (userConnectedData?.data) {
            setUser(userConnectedData.data.user);
            setListWorkspace(userConnectedData.data.workspace);
            if (localStorage.getItem('idWorkspace') === null && userConnectedData.data.workspace.length > 0) {
                const selectedWorkspace = userConnectedData.data.workspace[0];
                setWorkspace(selectedWorkspace);
                setSubscription(selectedWorkspace.feature);
                localStorage.setItem('idWorkspace', selectedWorkspace.workspaceId);
            } else {
                const workspace = userConnectedData.data.workspace.find(w => String(w.workspaceId) === String(localStorage.getItem('idWorkspace')));
                setWorkspace(workspace);
                setSubscription(workspace.feature);
            }
        }
    }, [userConnectedData]);

    useEffect(() => {
        if (msgNotReadCount) {
            setNbMessageNotRead(msgNotReadCount);
        }
    }, [msgNotReadCount]);

    useEffect(() => {
		if (!loggedIn) {
			localStorage.removeItem('token');
			localStorage.removeItem('idWorkspace');
			setUser(null);
		}
	}, [loggedIn]);

    client.interceptors.request.use(
        (config) => {
          if (localStorage.getItem('token')) {
            config.headers = {
              ...config.headers,
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            };
          }
          return config;
        },
        (error) => Promise.reject(error),
    );

    client.interceptors.response.use((response) => response, (error) => {
        const status = error.response ? error.response.status : null;
        if (status === 401) {
            setLoggedIn(false);
            localStorage.removeItem("token");
        }
        return Promise.reject(error);
    });

    return (
        <StoreContext.Provider value={{
            lang, setLang,
            isMenuOpen, setIsMenuOpen,
            loggedIn, setLoggedIn,
            user, setUser,
            workspace, setWorkspace,
            listWorkspace, setListWorkspace,
            nbMessageNotRead, setNbMessageNotRead,
            isTrialOffer, setIsTrialOffer,
            subscription, setSubscription,
        }}>
            {children}
        </StoreContext.Provider>
    );
}

export const useAppContext = () => useContext(StoreContext);
