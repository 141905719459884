import './statsClick.scss';
import { useTranslation } from 'react-i18next';

export const StatsClick = (props) => {
    const { dataClick } = props;
    const { t } = useTranslation();

    return (
        <div className="stats-click">
            <h3>Statistiques de liens</h3>
            <table>
                <thead>
                    <tr>
                        <th>Texte du bouton</th>
                        <th>URL</th>
                        <th className="nbClickTh">Nombre de clics</th>
                    </tr>
                </thead>
                <tbody>
                    {dataClick && dataClick.map((item, index) => (
                        <tr key={index}>
                            <td>{item.btnTextOrigin}</td>
                            <td className="url">{item.originUrl}</td>
                            <td className="nbClick">{item.clicks}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
  	);
}