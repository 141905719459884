import './whatsAppExample.scss';
import defaultCarrousel from "../../assets/images/default-carrousel.png";
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faPhone } from '@fortawesome/free-solid-svg-icons';

// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

export const WhatsAppExampleCampaign  = ({template, host}) => {
    const baseUrl = host;
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const [footer, setFooter] = useState('');
    const [btnUrl, setBtnUrl] = useState([]);
    const [btnPhone, setBtnPhone] = useState([]);
    const [btnStopMarket, setBtnStopMarket] = useState([]);
    const [media, setMedia] = useState('');
    const [titleType, setTitleType] = useState('');
    const [carrouselItems, setCarrouselItems] = useState([]);

    const handleWhatsAppForm = (content) => {
		if (!content) return "";
        return content
			.replace(/(^|\s)\*(?!\s)([^\s*].*?[^\s*])\*(?!\S)/g, '$1<strong>$2</strong>')
			.replace(/(^|\s)_(?!\s)([^\s_].*?[^\s_])_(?!\S)/g, '$1<em>$2</em>')
			.replace(/\n/g, '<br>')
			.replace(/\\n/g, '<br>'); 
	};

    useEffect(() => {
        setMessage('');
        setTitle('');
        setFooter('');
        setBtnUrl([]);
        setBtnPhone([]);
        setBtnStopMarket([]);
        setMedia('');
        setTitleType('');
        setCarrouselItems([]);
    }, [template]);

	return (
		<div className="whatsapp-chat">
			<div className="chat-header">
				<p></p>
			</div>
			<div className="chat-messages">

				<div className="contentMessage">

					<div className="title">
                        {template?.selectTitle?.id === 2 && (
                            template?.title
                        )}
					</div>

					<div className="image">
                        {template?.selectTitle?.id === 3 && template?.image && (
							<div className="upload-img">
                                <img
                                    src={template?.image?.mediaPath}
                                    alt="image"
                                />
                            </div>
                        )}
					</div>

                    <div className="video">
                        {template?.selectTitle?.id === 3 && template?.video && (
                            <div className="video-container">
                                <video controls className="styled-video">
                                    <source
                                        src={template?.video?.mediaPath}
                                        type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        )}
                    </div>

					<div className="message" dangerouslySetInnerHTML={{ __html: handleWhatsAppForm(template?.content) }}></div>

					<div className="footer">
						{template?.footer}
					</div>
                    
				</div>

                <div className="carrousel">
					<Swiper spaceBetween={5} slidesPerView={1.25}>
						{carrouselItems?.map((cards, index) => (
							<SwiperSlide>
								<div className="carrousel-item">
                                    {cards.components.map((compo, index) => {
                                        if (compo.type === 'HEADER') {
                                            return (
                                                <img key={`header-${index}`} src={defaultCarrousel} alt="placeholder" />
                                            );
                                        } else if (compo.type === 'BODY') {
                                            return (
                                                <div key={`body-${index}`} className="carrousel-content" dangerouslySetInnerHTML={{ __html: compo?.text }}></div>
                                            );
                                        } else if (compo.type === 'BUTTONS') {
                                            return (
                                                <div key={`buttons-${index}`} className="actions">
                                                    {compo.buttons.map((button, buttonIndex) => (
                                                        <div className="button" key={`button-${buttonIndex}`}>
                                                            {button.type === 'URL' ? (
                                                                <a href={button.url} target="_blank" rel="noreferrer">
                                                                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                                                                    {button.text}
                                                                </a>
                                                            ) : button.type === 'PHONE_NUMBER' ? (
                                                                <a href={`tel:${button.phoneNumber}`} target="_blank" rel="noreferrer">
                                                                    <FontAwesomeIcon icon={faPhone} />
                                                                    {button.text}
                                                                </a>
                                                            ) : null}
                                                        </div>
                                                    ))}
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
								</div>
							</SwiperSlide>
						))}
					</Swiper>
				</div>

				<div className="button-list">
                    {template?.listButtons?.map((button, index) => {
                        if (button.type === "web") {
                            return (
                            <div className="button" key={index}>
                                {button.url ? (
                                <a href={button.url} target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                                    {button.text}
                                </a>
                                ) : (
                                button.text
                                )}
                            </div>
                            );
                        } else if (button.type === "phone") {
                            return (
                            <div className="button" key={index}>
                                {button.phone_number ? (
                                <a href={`tel:${button.phone_number}`} target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon icon={faPhone} />
                                    {button.text}
                                </a>
                                ) : (
                                button.text
                                )}
                            </div>
                            );
                        } else if (button.type === "stopMarket") {
                            return (
                            <div className="button" key={index}>
                                {button.text}
                            </div>
                            );
                        }
                        return null;
                    })}
				</div>
				
			</div>
		</div>
	);
};
