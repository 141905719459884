import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '@mui/material';
import { FormAutocomplete } from '../../UI/Form/FormAutocomplete';
import { FormInputText } from '../../UI/Form/FormInputText';
import { FormButtonSubmit } from '../../UI/Form/FormButtonSubmit';
import { FormPhoneInputWithCountry } from '../../UI/Form/FormPhoneInputWithCountry';
import { getOneContact, updateContact } from '../../../store/index.service';
import { Toastify } from "../../UI/Toastify/Toastify";

export const UpdateContact = ({ handleClosePopup, idContact, listGroup, setRefreshDataGroup, handleGetData }) => {
    const { control, handleSubmit, setValue, getValues } = useForm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getOneContact(idContact).then(res => {
            Object.keys(getValues()).forEach(key => {
                setValue(key, res.data[key]);
            });

            let groups = [];
            res.data.groups.forEach(group => {
                groups.push({ id: group.id, title: group.name });
            });

            setValue("group", groups);
        });
    }, [idContact]);


    const onSubmit = (data) => {
        setLoading(true);
        updateContact(data, idContact).then(res => {
            setLoading(false);
            handleClosePopup();
            Toastify("updateContact", "success");
            setRefreshDataGroup(true);
            handleGetData();
        });
    }

    return (
        <div className='popupCreateHorseQuick popup-container'>

            <h2>Modifier un contact</h2>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                    <FormInputText name="firstName" control={control} label="Prénom" rules={{ required: false }} />
                </div>
                <div className="form-group">
                    <FormInputText name="lastName" control={control} label="Nom" rules={{ required: false }} />
                </div>

                <div className="form-group">
                    <FormPhoneInputWithCountry name="phone" control={control} label="Téléphone*" rules={{ required: true }} />
                </div>
                <div className="form-group">
                    <FormInputText name="email" control={control} label="Email" rules={{ required: false }} />
                </div>
                <div className="form-group"> 
                    <FormAutocomplete
                        name="groups"
                        multiple
                        control={control}
                        label="Groupes"
                        rules={{ required: false }}
                        options={listGroup}
                        getOptionLabel={option => option.name}
                        renderOption={(props, option) =>
                            <li {...props} key={option.id}>
                                {option.title}
                            </li>
                        }
                        clearable={true}
                    />
                </div>

                <div className="btn-container">
                    <Button variant="text" size="small" className="cancel" onClick={handleClosePopup}>Annuler</Button>
                    <FormButtonSubmit loading={loading} label="Modifier" />
                </div>
            </form>

        </div>
    )
}