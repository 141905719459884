import './selectFile.scss';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { extractLineFile } from '../../../../store/services/contact';

export const SelectFile = ({ onFileProcessed }) => {
    const [fileNames, setFileNames] = useState([]);

    const onDrop = useCallback((acceptedFiles) => {
        extractLineFile(acceptedFiles[0]).then((data) => {
            setFileNames(acceptedFiles.map(file => file.name));
            onFileProcessed(data.data);
        });
    }, [onFileProcessed]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' });

    return (
        <div className="selectFile" {...getRootProps()} >
            <input {...getInputProps()} />
            {
                isDragActive ?
                <p>Glissez les fichiers ici...</p> :
                <p>
                    Glissez et déposez des fichiers ici, ou cliquez pour sélectionner des fichiers
                    <br /> <br /> (uniquement des fichiers CSV ou Excel). 
                    <br /> <br />
                    <span>Assurez-vous que les indicateurs téléphoniques sont correctement renseignés dans le fichier (ex : +33, +44...).</span>
                </p>
            }
            {fileNames.length > 0 && (
                <div>
                    <h4>Fichier importé :</h4>
                    <ul>
                        {fileNames.map(fileName => (
                            <li key={fileName}>{fileName}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};
