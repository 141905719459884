import './statsItem.scss';

import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';


export const StatsItem = (props) => {
    const { pourcent, nbMessage, title, contentTooltip, color } = props;
    const { t } = useTranslation();

    return (
        <div className="kpi-item">
            <div className="stats">
                <h3 className={color}>     
                    {pourcent} %   
                    {/*            
                    <Tooltip title={t({contentTooltip})}>
                        <InfoIcon style={{ marginLeft: 10, fontSize: 'medium', verticalAlign: 'middle' }} />
                    </Tooltip>
                    */}
                </h3>
                <p>{nbMessage} messages</p>
            </div>
            <p>{title}</p>
        </div>
  	);
} 