import './statsGraph.scss';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { useTranslation } from 'react-i18next';

export const StatsGraph = ({ dataCampaign }) => {
    const { t } = useTranslation();

    // Générer les données pour le graphique
    const generateGraphData = (data) => {
        if (!data) return [];
        
        const totalSent = data.nbSend || 1; // Utiliser 1 par défaut pour éviter division par 0

        return [
            {
                name: "Lus",
                value: totalSent > 0 
                    ? parseFloat((((data.nbView || 0) / totalSent) * 100).toFixed(1)) 
                    : 0,
                nbMessage: data.nbView || 0,
            },
            {
                name: "Non lus",
                value: totalSent > 0 
                    ? parseFloat((((data.nbNotView || 0) / totalSent) * 100).toFixed(1)) 
                    : 0,
                nbMessage: data.nbNotView || 0,
            },
            {
                name: "Échoués & Spam",
                value: totalSent > 0 
                    ? parseFloat((((data.nbFail || 0) + (data.nbSpam || 0)) / totalSent * 100).toFixed(1)) 
                    : 0,
                nbMessage: (data.nbFail || 0) + (data.nbSpam || 0),
            }
        ];
        
    };

    // Données pour le graphique
    const data = generateGraphData(dataCampaign);

    const COLORS = ['#4bb475', '#ba90b9', '#f66e6e'];

    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
        index,
    }) => {
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5; // Rayon intermédiaire
        const x = cx + radius * Math.cos(-midAngle * RADIAN); // Position X
        const y = cy + radius * Math.sin(-midAngle * RADIAN); // Position Y
    
        // Récupération des données pour le segment actuel
        const { name, nbMessage } = data[index];
        const percentage = (percent * 100).toFixed(1); // Calcul du pourcentage arrondi
    
        // Retourne le texte avec plusieurs lignes
        return (
            <text
                x={x}
                y={y}
                fill="white"
                textAnchor="middle" // Centre le texte horizontalement
                dominantBaseline="central" // Centre le texte verticalement
            >
                <tspan x={x} dy="-1em" style={{ fontSize: '14px', fontWeight: 'bold' }}>
                    {name}
                </tspan> {/* Nom (Lus, Non lus, Échoués) */}
                <tspan x={x} dy="1.2em" style={{ fontSize: '20px' }}>
                    {percentage} %
                </tspan> {/* Pourcentage */}
                <tspan x={x} dy="1.5em" style={{ fontSize: '12px', opacity: 0.8 }}>
                    {nbMessage} messages
                </tspan> {/* Nombre de messages */}
            </text>
        );
    };
    

    return (
        <div className="kpi-graph">
            {/* Ajustez la hauteur pour correspondre à la taille souhaitée */}
            <ResponsiveContainer width="100%" height={400}>
                <PieChart>
                    <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={160}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};
