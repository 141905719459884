import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { DataGridPro, GridActionsCellItem } from '@mui/x-data-grid-pro';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

// Components
import { Popup } from "../../components/UI/Popup/Popup";
import { ConfirmDelete } from "../../components/UI/Popup/ConfirmDelete";
import { ConfirmPopup } from "../../components/UI/Popup/ConfirmPopup";
import { Toastify } from "../../components/UI/Toastify/Toastify";

// Services
import { getListTemplate, deleteTemplate, duplicateTemplate } from '../../store/index.service';

// Hooks
import { useAppContext } from "../../store/store";

// Assets
import iconUpdate from '../../assets/images/icons/icon-edit.svg';
import iconRemove from '../../assets/images/icons/icon-trash.svg';
import iconDuplicate from '../../assets/images/icons/icon-duplicate.svg';

export const Template = () => {
    const { t } = useTranslation();
    const { workspace } = useAppContext();
    const navigate = useNavigate();

    const [listTemplate, setListTemplate] = useState({loading: true, data: []});
    const [showPopupConfirmDelete, setShowPopupConfirmDelete] = useState({show: false});
    const [showPopupConfirmDuplicate, setShowPopupConfirmDuplicate] = useState({show: false});

    const columns = [
        { 
            field: 'name', 
            headerName: t('template_table_title_name'),
            flex: 2,
            renderHeader: (params) => (
                <span>
                    {params.colDef.headerName}
                        <Tooltip title={t('template_table_title_name_tooltip')}>
                        <InfoIcon style={{ marginLeft: 10, fontSize: 'medium', verticalAlign: 'middle' }} />
                    </Tooltip>
                </span>
            ),
        },
        { 
            field: 'statusMeta', 
            headerName: t('template_table_title_status'), 
            flex: 1,
            renderHeader: (params) => (
                <span>
                    {params.colDef.headerName}
                        <Tooltip title={t('template_table_title_status_tooltip')}>
                        <InfoIcon style={{ marginLeft: 10, fontSize: 'medium', verticalAlign: 'middle' }} />
                    </Tooltip>
                </span>
            ),
            renderCell: (params) => {
                let color;
                switch (params.value) {
                    case 'APPROVED':
                        color = 'approved';
                        break;
                    case 'REJECTED':
                        color = 'rejected';
                        break;
                    case 'PENDING':
                        color = 'pending';
                        break;
                    default:
                        color = 'draft';
                        break;
                }
                return (
                    <div className={`status ${color}`}>
                        <p>{params.value || 'Brouillon'}</p>
                    </div>
                );
            }
        },
        {
            field: 'quality', 
            headerName: t('template_table_title_quality'), 
            flex: 0.8,
            renderHeader: (params) => (
                <span>
                    {params.colDef.headerName}
                        <Tooltip title={t('template_table_title_quality_tooltip')}>
                        <InfoIcon style={{ marginLeft: 10, fontSize: 'medium', verticalAlign: 'middle' }} />
                    </Tooltip>
                </span>
            ),
            renderCell: (params) => {
                const quality = params.value;
                let color;
                switch (quality) {
                    case 'GREEN':
                        color = 'green';
                        break;
                    case 'YELLOW':
                        color = 'orange';
                        break;
                    case 'RED':
                        color = 'red';
                        break;
                    default:
                        color = 'grey';
                }
    
                return (
                    <div style={{ 
                        width: '15px',
                        height: '15px',
                        backgroundColor: color,
                        borderRadius: '50%',
                        marginTop: '15px'
                    }} />
                );
            }
        },
        { field: 'createdAt', headerName: t('template_table_title_created'), type: 'dateTime', flex: 1, valueGetter: (params) => { return params ? new Date(params.date) : null;}},
        { field: 'actions', type: "actions", flex: 0.8, getActions: (params) => {
            const actions = [
                workspace?.workspaceRole === 'admin' && params.row.status === 'DRAFT' ? (
                    <GridActionsCellItem
                        icon={<img src={iconUpdate} alt="icon update" />}
                        onClick={() => navigate(`/template/${params.row.id}`)}
                        label="Modifier"
                    />
                ) : null,
                workspace?.workspaceRole === 'admin' && params.row.status !== 'DRAFT' ? (
                    <GridActionsCellItem
                        icon={<img src={iconDuplicate} alt="icon update" />}
                        onClick={() => setShowPopupConfirmDuplicate({ show: true, id: params.row.id })}
                        label="Dupliquer"
                    />
                ) : null,
                workspace?.workspaceRole === 'admin' ? (
                    <GridActionsCellItem
                        icon={<img src={iconRemove} alt="icon remove" />}
                        onClick={() => setShowPopupConfirmDelete({ show: true, id: params.row.id })}
                        label="Supprimer"
                    />
                ) : null
            ];
        return actions.filter((action) => action !== null);
    }}
    ];

    useEffect(() => {        
        handleGetData();
    }, []);

    const handleGetData = () => {
        getListTemplate().then(res => {
            setListTemplate({loading: false, data: res ?? []});
        });
    }  

    const handleDelete = () => {
        deleteTemplate(showPopupConfirmDelete.id).then(() => {
            handleGetData();
            Toastify("deleteTemplate", "success");
        });
    }

    const handleDuplicate = () => {
        duplicateTemplate(showPopupConfirmDuplicate.id).then(() => {
            handleGetData();
            Toastify("duplicateTemplate", "success");
        });
    }

    return (
        <div className="template">

            <div className="background-white-title">
                <div className="title">
                    <h1>{t('template_title')}</h1>
                    <p>Vous devez vérifier votre entreprise sur Meta pour envoyer plus de 250 messages</p>
                </div>
                {workspace?.workspaceRole === 'admin' &&
                    <div className="actions">
                        <Link to="/template/create" className="btn-add">{t('template_btn_title')}</Link>
                    </div>
                }
            </div>
            
            <DataGridPro 
                autoHeight 
                rows={listTemplate.data} 
                columns={columns} 
                pageSize={20} 
                className="table" 
                loading={listTemplate.loading} 
                pagination
                pageSizeOptions={[20, 50, 100]}
            />

            { showPopupConfirmDelete.show &&
                <Popup closePopup={() => setShowPopupConfirmDelete({ show: false })} customContent={true}>
                    <ConfirmDelete msg={t('template_popup_delete')} handleDelete={handleDelete} />
                </Popup>
            }

            { showPopupConfirmDuplicate.show &&
                <Popup closePopup={() => setShowPopupConfirmDuplicate({ show: false })} customContent={true}>
                    <ConfirmPopup msg={t('campaign_popup_duplicate')} handleDelete={handleDuplicate} />
                </Popup>
            }

        </div>
    )
}