import api from '../api';

export const getListContact = async () => await api.get('/api/workspace/' + localStorage.getItem('idWorkspace') + '/contact');

export const getAllListContact = async () => await api.get('/api/workspace/' + localStorage.getItem('idWorkspace') + '/contact/all');

export const getOneContact = async (id) => await api.get('/api/workspace/' + localStorage.getItem('idWorkspace') + '/contact/' + id);

export const createContact = (data) => api.post('/api/workspace/' + localStorage.getItem('idWorkspace') + '/contact', data)

export const updateContact = async (data, id) => api.put('/api/workspace/' + localStorage.getItem('idWorkspace') + '/contact/' + id, data)

export const removeContacts = async (data) => api.delete('/api/workspace/' + localStorage.getItem('idWorkspace') + '/contacts', {data: data})

export const removeContact = async (id) => api.delete('/api/workspace/' + localStorage.getItem('idWorkspace') + '/contact/' + id)

export const addContactsGroup = async (data) => api.put('/api/workspace/' + localStorage.getItem('idWorkspace') + '/contacts/group/', data)

export const removeContactGroups = async (idGroup, id) => api.delete('/api/workspace/' + localStorage.getItem('idWorkspace') + '/contact/' + id + '/group/' + idGroup)

export const removeContactsGroups = async (data, id) => api.post('/api/workspace/' + localStorage.getItem('idWorkspace') + '/contacts/group/' + id, data)

export const getFieldCustom = async () => await api.get('api/workspace/' + localStorage.getItem('idWorkspace') + '/fieldCustom');

export const extractLineFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    return api.post('/api/workspace/' + localStorage.getItem('idWorkspace') + '/extract', formData, {        
        headers: {
            'Content-Type': 'multipart/form-data',
        }}
    );
};

export const importContact = async (data, idContactImport, selectedGroups) => {
    let newData = {
        mapping: {
            data
        },
        idContactImport: idContactImport,
        groups: selectedGroups
    };

    return api.post('/api/workspace/' + localStorage.getItem('idWorkspace') + '/import/contact', newData)
};

export const updateContactBlacklist = async (id) => api.post('/api/workspace/' + localStorage.getItem('idWorkspace') + '/blacklist/' + id)

export const createField = async (data) => await api.post('/api/workspace/' + localStorage.getItem('idWorkspace') + '/contact-field', data);
