import { useEffect, CSSProperties  } from "react";
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useAppContext } from "./store/store";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from "react-spinners/ClipLoader";

// Components
import { Header } from './components/Header/Header';
import { Navigation } from './components/Navigation/Navigation';
import { TrialOffer } from './components/TrialOffer/TrialOffer';
import { Crisp } from './components/UI/Crisp/Crisp';
import { ReportBug } from './components/UI/ReportBug/ReportBug';

// Pages
import { Login } from './pages/Auth/Login/Login';
import { Register } from './pages/Auth/Register/Register';
import { RequestPassword } from './pages/Auth/RequestPassword/RequestPassword';
import { ResetPassword } from './pages/Auth/ResetPassword/ResetPassword';

import { PrivacyPolicy } from './pages/PrivacyPolicy/PrivacyPolicy';
import { LegalMention } from './pages/PrivacyPolicy/LegalMention';
import { Faq } from './pages/Faq/Faq';

import { Dashboard } from './pages/Dashboard/Dashboard';
import { Contacts } from './pages/Contacts/Contacts';
import { Messaging } from './pages/Messaging/Messaging';
import { Campaign } from './pages/Campaign/Campaign';
import { OneCampaign } from './pages/Campaign/OneCampaign/OneCampaign';
import { CreateCampaign } from './pages/Campaign/CreateCampaign';
import { UpdateCampaign } from './pages/Campaign/UpdateCampaign';
import { Template } from './pages/Template/Template';
import { CreateTemplate } from './pages/Template/CreateTemplate';
import { PersonalInfo } from './pages/Account/PersonalInfo/PersonalInfo';
import { Workspace } from './pages/Account/Workspace/Workspace';
import { Invoice } from './pages/Account/Invoice/Invoice';
import { Subscription } from './pages/Account/Subscription/Subscription';
import { Integration } from './pages/Account/Integration/Integration';

const RedirectToDashboard = () => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate('/dashboard');
    }, [navigate]);
    return null;
};

const override: CSSProperties = {
	display: "block",
	margin: "0 auto",
	marginTop: "20%"
};

export const WhakupRouter = () => {
	const { loggedIn, isTrialOffer, user } = useAppContext();

	if (!loggedIn) {
		return (
			<Routes>
				<Route path="/" element={<Login />} />
				<Route path="/mot-de-passe-oublier" element={<RequestPassword />} />
				<Route path="/reset-password/:token" element={<ResetPassword />} />
				<Route path="/inscription" element={<Register />} />
				<Route path="/inscription/:token" element={<Register />} />
				<Route path="/politique-de-confidentialite" element={<PrivacyPolicy />} />
				<Route path="/mentions-legales" element={<LegalMention />} />
				<Route path="*" element={<Navigate replace to="/" />} />
			</Routes>
		);
	}

	if (isMobile) {
		return (
			<div className="whakup-container">
				<div className="mobile-container">
					<h1>Affichage sur ordinateur uniquement</h1>
					<p>L'application est optimisée pour un usage sur navigateur d'ordinateur. Merci de vous reconnecter depuis un ordinateur.</p>
				</div>
			</div>
		)
	}

	if (!user) {
		return (
			<ClipLoader
				color={"#00a2ff"}
				loading={true}
				size={75}
				cssOverride={override}
			/>
		)
	}

	return (
		<div className="whakup-container">
			<TrialOffer />
			<Navigation />
			<div className="whakup-content">
				<Header />
				<div className="whakup-content-container">
					<div className={isTrialOffer ? 'isTrial' : ''}>
						<Routes>
							<Route path="/dashboard" element={<Dashboard />} />
							<Route path="/dashboard/invit/:token" element={<Dashboard />} />
							<Route path="/contact" element={<Contacts />} />
							<Route path="/messagerie" element={<Messaging />} />

							<Route path="/campagnes" element={<Campaign />} />
							<Route path="/campagnes/:id" element={<OneCampaign />} />
							<Route path="/campagnes/create" element={<CreateCampaign />} />
							<Route path="/campagnes/update/:id" element={<UpdateCampaign />} />

							<Route path="/template" element={<Template />} />
							<Route path="/template/create" element={<CreateTemplate />} />
							<Route path="/template/:id" element={<CreateTemplate isEditing />} />
							
							<Route path="/compte/profile" element={<PersonalInfo />} />
							<Route path="/compte/espace-de-travail" element={<Workspace />} />
							<Route path="/compte/facture" element={<Invoice />} />
							<Route path="/compte/abonnement" element={<Subscription />} />
							<Route path="/compte/meta" element={<Integration />} />
							
							<Route path="/politique-de-confidentialite" element={<PrivacyPolicy />} />
							<Route path="/mentions-legales" element={<LegalMention />} />
							<Route path="/faq" element={<Faq />} />

							{/* Rediriger vers le tableau de bord si connecté et essayant d'accéder à "/" */}
							<Route path="/" element={<RedirectToDashboard />} />
							{/* Rediriger vers le tableau de bord si une route non définie est demandée */}
							<Route path="*" element={<Navigate replace to="/dashboard" />} />
						</Routes>
					</div>
				</div>
				<ReportBug />
				<Crisp />
				<ToastContainer 
					position="top-right"
					autoClose={5000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss={false}
					draggable={false}
					pauseOnHover={false}
					theme="colored"
				/>
			</div>
		</div>
	);
}