import './header.scss';
import frFlag from "../../assets/images/icons/fr.svg";
import enFlag from "../../assets/images/icons/en.svg";
import defaultAvatar from "../../assets/images/default-profile.svg";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useState, useRef, useEffect, useContext } from 'react';
import { StoreContext } from '../../store/store';

// Services
import { logOut } from "../../store/index.service";

export const Header = () => {
    const { lang, setLang, setLoggedIn, user = {}, isTrialOffer } = useContext(StoreContext);
    const [dropdownUser, setDropdownUser] = useState(false);
    const [dropdownLang, setDropdownLang] = useState(false);
    const userDropdownRef = useRef(null);
    const langDropdownRef = useRef(null);

    const handleClickOutside = (event) => {
        if (userDropdownRef.current && !userDropdownRef.current.contains(event.target)) {
            setDropdownUser(false);
        }
        if (langDropdownRef.current && !langDropdownRef.current.contains(event.target)) {
            setDropdownLang(false);
        }
    };

    const logout = () => {
        logOut();
        setLoggedIn(false);
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleLanguageChange = (lang) => {
        setLang(lang);
        setDropdownLang(false);
    };

    return (
        <header className={isTrialOffer ? 'isTrial' : ''}>
            <div className="header-container">

                <Link to="/compte/abonnement" className="btn"><span>Améliorer mon plan</span></Link>

                <div className="user" ref={userDropdownRef} onClick={() => setDropdownUser(!dropdownUser)}>
                    <img src={defaultAvatar} alt="user" />
                    <p>{user.firstName} {user.lastName} </p>
                    <FontAwesomeIcon icon={faChevronDown} />

                    {dropdownUser &&
                        <div className="user-nav">
                            <Link to="/compte/profile">Profil</Link>
                            <Link to="/compte/abonnement">Abonnement</Link>
                            <Link onClick={() => logout()}>Déconnexion</Link>
                        </div>
                    }
                </div>

                <div className="lang" ref={langDropdownRef} onClick={() => setDropdownLang(!dropdownLang)}>
                    <img src={lang === "fr" ? frFlag : enFlag} alt="language" />

                    {dropdownLang &&
                        <div className="lang-nav">
                            <div onClick={() => handleLanguageChange("fr")}>
                                <img src={frFlag} alt="French" />
                            </div>
                            <div onClick={() => handleLanguageChange("en")}>
                                <img src={enFlag} alt="English" />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </header>
    );
};
