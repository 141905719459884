import api from '../api';

export const getListGroups = async () => {
    const result = await api.get('/api/workspace/' + localStorage.getItem('idWorkspace') + '/groups' , {});
    return(result.data);
}

export const getListContactByGroup = async (idGroup) => await api.get('/api/workspace/' + localStorage.getItem('idWorkspace') + '/groups/' + idGroup + '/contacts')

export const getOneGroup = async (id) => await api.get('/api/workspace/' + localStorage.getItem('idWorkspace') + '/groups/' + id);

export const createGroup = (data) => api.post('/api/workspace/' + localStorage.getItem('idWorkspace') + '/groups', data)

export const updateGroup = async (data, id) => api.put('/api/workspace/' + localStorage.getItem('idWorkspace') + '/groups/' + id, data)

export const removeGroup = async (id) => api.delete('/api/workspace/' + localStorage.getItem('idWorkspace') + '/groups/'+ id)

export const generateGroupsCampaign = (data, id) => api.post('/api/workspace/' + localStorage.getItem('idWorkspace') + '/campaign/' + id + '/groups', data)