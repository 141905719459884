import { Button } from '@mui/material';

export const ConfirmCampaign = ({ handleClosePopup, handleConfirm, msg = "", form }) => {

    const handleSubmit = () => {
        handleConfirm();
        handleClosePopup();
    }

    console.log(form);

    return (
        <div className='popupCreateHorseQuick'>

            <h2>Envoyer la campagne</h2>

            <div className="content-popup-campaign">
                <div className="info-campaign">
                    <p>Titre : {form?.title}</p>
                </div>
                <div className="info-campaign">
                    {form?.groups?.map((group, index) => (
                        <p key={index}>
                            Groupe : {group.title} - {group.nbContact} Contacts
                        </p>
                    ))}
                </div>
                <div className="info-campaign">
                    <p>Template : {form?.template?.name}</p>
                </div>
                <div className="info-campaign">
                    <p>
                        {form?.sendProgrammation?.title}
                        {form?.sendProgrammation?.id === 1 && <span> : {form?.dateProgrammation} à {form?.timeProgrammation}</span>}
                    </p>
                </div>
            </div>

            <div className="btn-container">

                <Button 
                    variant="text"
                    size="small"
                    className="cancel"
                    onClick={handleClosePopup}
                >
                    Modifier la campagne
                </Button>

                <Button 
                    variant="contained"
                    disableElevation
                    onClick={handleSubmit}
                >
                    Confirmer la campagne
                </Button>

            </div>

        </div>
    )
}