import React from 'react';


export const ConfirmImport = ({nbContact}) => {

    return (
        <div className="confirm-import">
            <h2>Importation réussie !</h2>
            <p>Contact importés : <span>{nbContact}</span></p>
        </div>
  	);
} 