import { useCallback } from 'react';
import './buttonAction.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';
import { FormInputText } from "../../../components/UI/Form/FormInputText";
import { useFormState, useForm } from 'react-hook-form';

export const ButtonCallActionPhone  = (props) => {
    const { control, index, remove, name, deleteTooltip } = props;

    const handleRemove = useCallback(() => {
        remove(index);
    }, [remove, index]);

    const phoneNumberPattern = /^\+\d{1,}$/; // Expression régulière pour vérifier le numéro de téléphone

    const containsEmoji = (value) => {
        const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F1E6}-\u{1F1FF}\u{1F004}\u{1F0CF}]/u;
        return emojiRegex.test(value);
    };

    return (
        <div className="buttonCallActionPhone">
            <div className="containerButton">
                <div className="buttonContainer">
                    <p>Texte du bouton</p>
                    <FormInputText
                        control={control}
                        name={`${name}[${index}].text`}
                        maxLength={25}
                        rules={{ validate: value => !containsEmoji(value) || "N'ajoutez pas d'émojis dans la zone de texte Pied de page", required: "Ce champ est obligatoire"}}
                    />
                </div>

                <div className="buttonContainer">
                    <p>Numéro de téléphone</p>
                    <FormInputText
                        control={control}
                        name={`${name}[${index}].phoneNumber`}
                        maxLength={20}
                        placeholder="+33123456789"
                        rules={{
                            required: "Ce champ est obligatoire",
                            validate: value => phoneNumberPattern.test(value) || "Le numéro de téléphone doit commencer par un indicatif, par exemple : +33123456789"
                        }}
                    />
                </div>
            </div>
            {deleteTooltip ? (
                <Tooltip title={deleteTooltip}>
                    <div className="deleteBtn" onClick={handleRemove}>
                        <FontAwesomeIcon icon={faTrash} />
                    </div>
                </Tooltip>
            ) : (
                <div className="deleteBtn" onClick={handleRemove}>
                    <FontAwesomeIcon icon={faTrash} />
                </div>
            )}
        </div>
    );
};
