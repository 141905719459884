import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { FormAutocomplete } from '../../UI/Form/FormAutocomplete';
import { FormButtonSubmit } from '../../UI/Form/FormButtonSubmit';
import { addContactsGroup } from '../../../store/index.service';
import { Toastify } from "../../UI/Toastify/Toastify";

export const AddContactToGroup = ({ handleClosePopup, listContact, listGroup, setRefreshDataGroup }) => {
    const { control, handleSubmit } = useForm();
    const [loading, setLoading] = useState(false);

    const onSubmit = (data) => {
        setLoading(true);

        let newData = {
            group: data,
            contact: listContact
        };

        addContactsGroup(newData).then(() => {
            const allGroupsLabel = data.group?.map(group => group.title).join(', ');
            setLoading(false);
            Toastify("addContactToGroup", "success", {
                name: allGroupsLabel,
                groupsCount: data.group?.length,
                contactsCount: listContact?.length
            });
            setRefreshDataGroup(true);
            handleClosePopup();
        });
    }

    return (
        <div className='popupCreateHorseQuick'>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                    <FormAutocomplete
                        name="group"
                        multiple
                        control={control}
                        label="Groupes"
                        rules={{ required: true }}
                        options={listGroup}
                        getOptionLabel={option => option.title}
                        renderOption={(props, option) =>
                            <li {...props} key={option.id}>
                                {option.title}
                            </li>
                        }
                        clearable={true}
                    />
                </div>


                <div className="btn-container">
                    <Button variant="text" size="small" className="cancel" onClick={handleClosePopup}>Annuler</Button>
                    <FormButtonSubmit loading={loading} label="Ajouter" />
                </div>
            </form>


        </div>
    )
}