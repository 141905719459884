import './register.scss';
import media from '../../../assets/images/WhatsApp-marketing-campaign-2.png';
import logoGreen from '../../../assets/images/logo-green.svg';
import { useState, useContext } from 'react';
import { useForm } from "react-hook-form";
import { FormInputText } from "../../../components/UI/Form/FormInputText";
import { Button } from '@mui/material';
import Cookies from 'js-cookie';
import ClipLoader from "react-spinners/ClipLoader";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { StoreContext } from '../../../store/store';
import { useTranslation } from 'react-i18next';
import { register, getUserConnected, getUserInvited } from "../../../store/index.service";

export const Register = () => {
    const { handleSubmit, control, setValue } = useForm();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const navigate = useNavigate();
    const { setLoggedIn, setUser } = useContext(StoreContext);
    const { token } = useParams();

    if (token) {
        getUserInvited(token)
            .then(res => {
                setValue('email', res.email);
                setValue('tokenInvit', token);
            })
    }


    const onSubmit = (data) => {
        setLoading(true);

        register(data)
            .then(res => {
                Cookies.set('loggedIn', true, { expires: 1 });
                localStorage.setItem('token', res.data.token);
                setLoggedIn(true);

                getUserConnected()
                    .then(res => {
                        const data = res.data;
                        localStorage.setItem("idUser", data.idUser)
                        setUser(data);
                        navigate("/dashboard");
                    })
                    .catch(err => {
                    })
                    .finally(() => setLoading(false))
            })
            .catch(err => {
                setError(
                    err.response.status === 401 ? err.response.status : "default"
                );
            })
            .finally(() => setLoading(false))

    }

    return (
        <div className="register">

            <div className="login-media">
                <img src={media} alt="media" />
            </div>

            <div className="login-form">
                <div className="logo-whakup">
                    <img src={logoGreen} alt="logo" />
                </div>

                <div className="title">
                    {token === undefined && 
                        <h1>Inscription</h1>
                    }

                    {token !== undefined &&
                        <h3>Vous avez été invité à rejoindre l'espace de travail</h3>
                    }
                </div>

                <div className="form">
                    <form name="login" className="register-form" onSubmit={handleSubmit(onSubmit)}>

                        { error &&
                            <div className='error-msg'>
                                <span>Cette adresse email est déjà utilisée</span>
                            </div>
                        }

                        {token === undefined &&
                            <div className="form-group">
                                <FormInputText name="workspaceName" control={control} rules={{ required: true }} label="Nom de l'entreprise" type="text" />
                            </div>
                        }

                        <div className="form-group">
                            <FormInputText name="firstName" control={control} rules={{ required: true }} label="Prénom" type="text" />
                        </div>

                        <div className="form-group">
                            <FormInputText name="lastName" control={control} rules={{ required: true }} label="Nom" type="text" />
                        </div>

                        <div className="form-group">
                            <FormInputText name="email" control={control} rules={{ required: true }} label="Email" type="email" />
                        </div>

                        <div className="form-group">
                            <FormInputText name="password" control={control} rules={{ required: true }} label="Mot de passe" type="password" />
                        </div>

                        {/* honeypot */}
                        <div className="form-group" style={{ display: 'none' }}>
                            <FormInputText name="token" control={control} rules={{ required: false }} label="token" type="text" />
                        </div>

                        <div className="form-group">
                            <p>En créant un compte, vous acceptez les <Link to="/politique-de-confidentialite">conditions générales d'utilisation</Link></p>
                        </div>


                        <Button className="btn-submit" variant="contained" disableElevation type="submit" size='large'>
                            <ClipLoader color={"#fff"} loading={loading} size={20} cssOverride={{ marginRight: 5 }} />
                            S'inscrire
                        </Button>

                        <div className="form-group">
                            <p className="no-account">Déjà un compte ? <Link to="/">Se connecter</Link></p>
                        </div>
                    </form>
                </div>

            </div>
        </div>
  	);
} 